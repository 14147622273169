import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import HeroChevron from "../../../components/hero/hero-chevron";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import VimeoVideoFrame from "../../../components/custom-widgets/vimeo-video-frame";
import Icon from "../../../components/custom-widgets/icon";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

const SeattleSeawolves = () => {
  const title = "Seattle Seawolves Rugby Team Partners with WaFd Bank";
  const description =
    "Learn about the partnership between WaFd Bank and Seattle's professional rugby team, the Seattle Seawolves. Find out how you can support your favorite Seattle team.";

  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: { eq: "hero/about-us/wafd-sports/seattle-seawolves/hero-seawolves-031524-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/about-us/wafd-sports/seattle-seawolves/hero-seawolves-031524-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/about-us/wafd-sports/seattle-seawolves/hero-seawolves-031524-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/about-us/wafd-sports/seattle-seawolves/hero-seawolves-031524-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/about-us/wafd-sports/seattle-seawolves/hero-seawolves-031524-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/about-us/wafd-sports/seattle-seawolves/hero-seawolves-031524-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/about-us/wafd-sports/seattle-seawolves/hero-seawolves-031524-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "seattle-seawolves-hero",
    ...getHeroImgVariables(imgData),
    altText: "Seawolves team standing together on field.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Always Win With the Seawolves Debit Card!"
          }
        },
        {
          id: 2,
          button: {
            class: "btn-white",
            text: "Open an Account",
            url: "/open-an-account",
            id: "seawolves-hero-cta"
          }
        }
      ]
    }
  };

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        property: "og:title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/about-us/wafd-sports/seattle-seawolves"
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-seawolves-031524.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/about-us",
      title: "About Us"
    },
    {
      id: 2,
      url: "/about-us/wafd-sports",
      title: "WaFd Sports"
    },
    {
      id: 3,
      active: true,
      title: "Seattle Seawolves"
    }
  ];

  const seawolvesVideo = {
    title: "Seattle Seawolves",
    vimeoId: "803630019",
    minHeight: "360px",
    class: "m-auto iframe w-100 border-radius-12"
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Seattle Seawolves</h1>
        <h3>
          WaFd Bank is proud to support the growth of Seattle's professional rugby team, the{" "}
          <a
            href="http://www.seattleseawolves.com/"
            target="_blank"
            rel="noopener noreferrer"
            id="seawolves-external-link"
          >
            Seattle Seawolves
          </a>
          .
        </h3>
        <div className="row">
          <div className="col-md-6">
            <h4 className="text-success">
              Love the Seattle Seawolves? Open a Free Checking* account with a Seattle Seawolves debit card.
            </h4>
            <div className="row my-3">
              <div className="col-lg-6 mb-4 mb-lg-0">
                <StaticImage
                  src="../../../images/credit-cards/thumbnail-consumer-seawolves-horizontal.jpg"
                  alt="WaFd Bank Seawolves theme debit card"
                  placeholder="blurred"
                  quality="100"
                />
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <Link id="open-bank-account-online-cta" to="/open-an-account" className="btn btn-primary w-100">
                    Open an Account
                  </Link>
                </div>
                <div>
                  <Link
                    id="compare-accounts-cta"
                    to="/personal-banking/checking-account"
                    className="btn btn-link w-100"
                  >
                    Compare Accounts
                  </Link>
                </div>
              </div>
            </div>
            <p className="text-sm text-gray-60" id="free-checking-disclaimer">
              *Only $25 to open. Insufficient Funds or Returned Check Charges may apply.{" "}
            </p>
          </div>
          <div className="col-md-6">
            <VimeoVideoFrame {...seawolvesVideo} />
          </div>
        </div>
      </section>
      <section className="bg-light">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-auto text-center">
              <StaticImage
                src="../../../images/third-party/seattle-seawolves/seattle-seawolves-primary-logo.png"
                alt="Seattle Seawolves Logo"
                placeholder="blurred"
                quality="100"
                style={{ width: "250px" }}
              />
            </div>
            <div className="col-md-7 col-lg-8">
              <h3>
                <span className="text-success">
                  &ldquo;WaFd Bank powers the Seawolves mission to win with excellence, to thrill our fans, and live the
                  traditions of rugby to inspire our community. Our players are filled with pride every time they pull
                  on our WaFd Bank branded kit and hit the pitch. The Seawolves couldn't be prouder to partner, serve,
                  and grow alongside our friends at WaFd Bank.&rdquo;
                </span>{" "}
                Brian Jones, Seattle Seawolves CEO and Minority Owner
              </h3>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="row mb-3">
          <div className="col-md-4 my-2">
            <StaticImage
              src="../../../images/third-party/seattle-seawolves/headshot-seawolves-2-031524.jpg"
              alt="Seattle Seawolves player holding WaFd Bank Seawolves debit card by chest."
              placeholder="blurred"
              quality="100"
            />
          </div>
          <div className="col-md-4 my-2">
            <StaticImage
              src="../../../images/third-party/seattle-seawolves/headshot-seawolves-1-031524.jpg"
              alt="Seattle Seawolves player holding football in front of neon green lights."
              placeholder="blurred"
              quality="100"
            />
          </div>
          <div className="col-md-4 my-2">
            <StaticImage
              src="../../../images/third-party/seattle-seawolves/headshot-seawolves-4-031524.jpg"
              alt="Seattle Seawolves player holding WaFd Bank Seawolves debit card by face."
              placeholder="blurred"
              quality="100"
            />
          </div>
        </div>
        <h3>Seattle Seawolves and WaFd Bank</h3>
        <h4>
          WaFd Bank is excited to announce an extended partnership with The Seattle Seawolves which includes front of
          jersey sponsorship and additional partnership assets for the 2024 Major League Rugby (MLR) season! A partner
          of the Seawolves since 2019, WaFd Bank is very proud to support the{" "}
          <span className="font-weight-bold">two-time MLR Champions</span> and hold all of the Seawolves' business
          banking accounts.
        </h4>
        <p>
          <Link to="/about-us/wafd-sports" className="text-decoration-none" id="wafd-sports-page-link">
            <Icon name="arrow-left" class="mr-1" />
            Back to WaFd Sports
          </Link>
        </p>
      </section>

      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default SeattleSeawolves;
